<template>
  <v-btn
    :color="hideResolvedComments ? 'green' : 'black'"
    outlined
    @click="toggleResolvedComments(!hideResolvedComments)">
    Hide Resolved
  </v-btn>
</template>
<script>
import {
  mapActions,
  mapState,
} from 'vuex';
export default {
  name: 'ToggleResolvedComments',
  computed: {
    ...mapState('Comments', ['hideResolvedComments']),
  },
  methods: {
    ...mapActions({
      toggleResolvedComments: 'Comments/toggleResolvedComments',
    }),
  },
};
</script>
