<template>
  <v-card
    class="sourcery__comments-card"
    height="100%"
    flat
    @dragleave.capture="dragEnd"
    @dragover.capture="dragOver">
    <!-- top section -->
    <div class="sourcery__comments-card--settings">
      <Followers
        v-if="!type && !cellPointer"
        :row-id="rowId"
        :access="addCollaboratorsAccess"
        :table-id="tableId"
        :followers="rowCommentsFollowers" />

      <div
        v-if="!type || !hideResolvedToggle"
        class="d-flex gap-3 align-center">
        <ProjectDetailsView v-if="!type" />

        <ToggleResolvedComments v-if="!hideResolvedToggle" />
      </div>
    </div>

    <!-- comments section -->
    <div class="sourcery__comments-card--main">
      <FileUpload
        ref="upload"
        v-model="files"
        :multiple="false"
        :drop="true"
        :drop-directory="false" />

      <DragAndDropArea
        absolute
        height="100%"
        max-width="100%"
        :is-dialog="false"
        :is-drag="$refs.upload && $refs.upload.dropActive"
        :class="[drag ? 'drag-drop-container__active' : '']" />

      <DragAndDropArea
        v-show="fileForPreview"
        :is-dialog="false"
        absolute
        max-width="100%"
        height="100%"
        :file="fileForPreview"
        :row-id="rowId"
        :type="type"
        :table-id="tableId"
        @close="fileForPreview = null"
        @uploadFile="createRowCommentAttachment" />

      <Comments
        ref="containerForComments"
        :data="{
          comments,
          nextToken,
          nextTokenForUnread,
        }"
        :edited-row-id="editedRowId"
        :files-src.sync="filesSrc"
        :is-disabled-comments="isDisabledComments"
        :row-id="rowId"
        :scrolled-comment="scrolledComment"
        :type="type"
        data-test="row_comments_comment_block"
        allow-resolve-comments
        @getRowComments="getRowComments"
        @editRowComment="editRowCommentAction"
        @manageCellCommentResolved="manageCellCommentResolved"
        @deleteRowComment="deleteRowComment" />
    </div>

    <!-- add comment -->
    <div>
      <RowCommentsForm
        ref="rowCommentsForm"
        :mentioned-users.sync="allMentionUsers"
        :private.sync="privateMode"
        :edit-id.sync="editedRowId"
        :message.sync="message"
        :value-blur.sync="valueBlur"
        :is-disabled-comments="isDisabledComments"
        :focus-message.sync="focusMessage"
        @editRowComment="editRowComment"
        @onPaste="onPaste"
        @createRowComment="createRowComment" />
    </div>
  </v-card>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { Storage } from 'aws-amplify';
import FileUpload from 'vue-upload-component';

import {
  PROJECT_COMMENTS_RELATED_SUBSCRIPTIONS,
  ROW_COMMENTS_RELATED_SUBSCRIPTIONS,
} from '@/constants/commentsSubscriptionMapping';

import Comments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowComments';
import DragAndDropArea from '@/components/App/AppDragAndDropArea';
import RowCommentsForm from '../RowCommentsForm';
import ToggleResolvedComments from '@/components/ProjectDetails/ProjectDetailsRowCommentsModal/ToggleResolvedComments';

import GetCommenCreatorAvatar from '@/mixins/getCommentCreatorAvatar';
import PasteClipboard from '@/mixins/PasteClipboard';

import CommentsApi from '@/services/graphql/comments';

import { hasEditorAccess } from '@/utils';

export default {
  name: 'RowCommentsCard',
  components: {
    Comments,
    DragAndDropArea,
    FileUpload,
    RowCommentsForm,
    ToggleResolvedComments,
    Followers: () => import('@/components/ProjectDetails/ProjectDetailsRowCommentsModal/ManageFollowers'),
    ProjectDetailsView: () => import('@/components/ProjectDetails/ProjectDetailsViews'),
  },
  mixins: [
    PasteClipboard,
    GetCommenCreatorAvatar,
  ],
  props: {
    hideResolvedToggle: {
      type: Boolean,
      default: false,
    },
    showCommentsModal: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
    mode: {
      type: String,
      default: null,
    },
    dialogForComments: {
      type: Boolean,
      default: false,
    },
    expandedModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    valueBlur: '',
    allMentionUsers: [],
    nextToken: null,
    nextTokenForUnread: null,
    fileForPreview: null,
    files: [],
    drag: false,
    filesSrc: {
    },
    editedRowId: null,
    attachDocModal: false,
    privateMode: null,
    message: null,
    focusMessage: false,
    localCellPointer: null,
    scrolledComment: null,
  }),
  computed: {
    isDisabledComments() {
      return !!this.$route.query.version || this.isUnsuccessfulPayment;
    },
    ...mapState(['avatars',
      'role', 'commentsSubscriptions',
    ]),
    ...mapState('ProjectDetailsTableSchedule', ['cellPointer']),
    ...mapState('Comments', ['commentsList', 'newCommentId', 'rowFollowers']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['premiumGroup', 'collaboratorGroup', 'userId']),
    ...mapGetters('ProjectDetails', ['statusOfProject']),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment']),
    comments: {
      get() {
        return this.commentsList;
      },
      set(val) {
        this.setComments(val);
      },
    },
    rowCommentsFollowers: {
      get() {
        return this.rowFollowers;
      },
      set(val) {
        this.setRowFollowers(val);
      },
    },
    slant() {
      return this.$store.getters['Comments/slant'](this.rowId);
    },
    projectId() {
      return this.$route.params.id;
    },
    dialog: {
      get() {
        return this.dialogForComments;
      },
      set(val) {
        this.$emit('update:dialogForComments', val);
      },
    },
    tableId() {
      return this.$store.state.ProjectDetailsTableSchedule.scheduleId;
    },
    addCollaboratorsAccess() {
      return (((this.statusOfProject === 'active' && hasEditorAccess(this.role)) || this.role === 'collaborator'));
    },
  },
  watch: {
    focusMessage(val) {
      if (!val) {
        this.localCellPointer = null;
      }
    },
    rowId(val) {
      if (!val) return;
      this.destroyCommentsData();
      this.subscribeCommentsRelated();
      this.getCommentsData();
    },
    cellPointer(val) {
      this.privateMode = '';
      if (val !== null) {
        this.setReadComments();
        this.comments = [];
        this.nextToken = null;
        this.nextTokenForUnread = null;
        this.requestsForRowComments();
      }
    },
    rowFollowers(val) {
      this.getUsersAvatars(val);
    },
    collaborators(val) {
      this.getUsersAvatars(val);
    },
    comments: {
      async handler(val) {
        val.forEach(async (comment) => {
          if (comment.thumbnailId && !this.filesSrc.hasOwnProperty(comment.thumbnailId)) {
            this.getThumbnail(comment.thumbnailId);
          }
          this.getAvatarsForComments(comment);
        });
      },
      deep: true,
      immediate: true,
    },
    files(val) {
      const { file } = val[val.length - 1];
      this.fileForPreview = file;
    },
    newCommentId: {
      handler(val) {
        if (val) {
          this.scrollToComment({
            id: val,
          });
        }
      },
      deep: true,
    },
    showCommentsModal: {
      handler(val) {
        if (!val) {
          this.destroyCommentsData();
          return;
        }
        this.getCommentsData();
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('dragover', (e) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e) => {
      e.preventDefault();
    }, false);
  },
  beforeDestroy() {
    this.destroyCommentsData();
  },
  mounted() {
    this.subscribeCommentsRelated();
  },
  methods: {
    ...mapActions([
      'handleError',
    ]),
    ...mapActions({
      setSlant: 'Comments/setSlant',
      getCommentCellPointers: 'Comments/getCommentCellPointers',
      subscribeCellCommentAmountChanging: 'Comments/subscribeCellCommentAmountChanging',
      subscribeRowComment: 'Comments/subscribeRowComment',
      deleteComment: 'Comments/deleteComment',
      setComment: 'Comments/setComment',
      subscribeProjectComment: 'Comments/subscribeProjectComment',
      getRowFollowers: 'Comments/getRowFollowers',
    }),
    ...mapMutations([
      'spinner',
      'clearCommentsSubscriptions']),
    ...mapMutations({
      setCellPointer: 'ProjectDetailsTableSchedule/setCellPointer',
      setCommentCellPointers: 'Comments/setCommentCellPointers',
      setComments: 'Comments/setComments',
      setRowFollowers: 'Comments/setRowFollowers',
    }),
    getUsersAvatars(val) {
      val?.forEach((item) => {
        const picture = item?.picture ?? '';
        if (picture && !this.avatars[picture]) {
          this.getAvatar({
            key: picture,
            getAvatars: true,
            context: this,
          });
        }
      });
    },
    async getCommentsData() {
      const { type, tableType, tableId, rowId, projectId } = this;
      if (!type) {
        this.getRowFollowers({
          projectId, rowId, tableId, tableType,
        });
        await this.getCommentCellPointers({
          rowId,
          tableType,
          tableId,
        });
      }
      this.requestsForRowComments();
    },
    destroyCommentsData() {
      this.clearCommentForm();
      this.fileForPreview = null;
      this.setReadComments();
      this.comments = [];
      this.nextToken = null;
      this.nextTokenForUnread = null;
      this.commentsSubscriptions.forEach(sub => sub?.unsubscribe());
      this.clearCommentsSubscriptions();
      this.setCellPointer(null);
      this.setCommentCellPointers([]);
    },
    async requestsForRowComments() {
      const { type, cellPointer } = this;
      if (cellPointer) {
        await this.getRowComments();
      } else {
        if (type || !type && this.slant) {
          await this.getRowComments();
        }
        if (!type) {
          await this.getRowComments({
            scanIndexForward: true,
          });
        }
      }
    },
    subscribeCommentsRelated() {
      const { type, projectId, rowId, tableId, tableType } = this;
      if (type !== 'project') {
        ROW_COMMENTS_RELATED_SUBSCRIPTIONS.map(({ criteria, action }) => {
          this.subscribeRowComment({
            projectId,
            rowId,
            criteria,
            action,
          });
        });
        this.subscribeCellCommentAmountChanging({
          tableId,
          tableType,
        });
      } else {
        PROJECT_COMMENTS_RELATED_SUBSCRIPTIONS.map(({ criteria, action }) => {
          this.subscribeProjectComment({
            projectId,
            PK: `SCHEDULE#${tableId}`,
            criteria,
            action,
          });
        });
      }
    },
    setReadComments() {
      const {
        projectId,
        tableId,
        tableType,
        rowId,
      } = this;
      let offersIDs = [];
      const messageIDs = this.comments.reduce((result, option) => {
        if (!option.read) {
          if (option.commentType === 'offer') {
            offersIDs = [...offersIDs, option.id];
          }
          return [...result, option.id];
        }
        return result;
      }, []);
      if (!messageIDs.length) return false;
      this.setSlant({
        projectId,
        tableId,
        tableType,
        messageIDs,
        offersIDs,
        ...(rowId && {
          rowId,
        }),
      });
    },
    async getThumbnail(id) {
      try {
        const url = await Storage.get(id, {
          customPrefix: {
            public: '',
          }, level: 'public',
        });
        this.$set(this.filesSrc, id, url);
      } catch (err) {
        console.log('err with set image', err);
      }
    },
    dragEnd() {
      this.drag = false;
    },
    dragOver() {
      this.drag = true;
    },
    async scrollToComment({ id, mentionedComment = false }) {
      try {
        await this.$nextTick();
        const commentsRows = this.$refs.containerForComments.$refs.commentsRows;
        if (!commentsRows) return;
        const comment = commentsRows.find(({ id: commentId = null } = {
        }) => {
          if (!commentId) return false;
          return commentId === `commentId_${id}`;
        });
        if (!comment) return;
        comment.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        if (mentionedComment && comment) {
          this.scrolledComment = id;
        }
      } catch (e) {
        console.log(e);
      }
    },
    manageCellCommentResolved(comment) {
      this.spinner(true);
      this.editRowComment({
        ...comment,
        cellCommentResolved: !comment.cellCommentResolved,
        manageCommentResolve: true,
      });
    },
    async deleteRowComment({ id: commentId, privacy }) {
      this.spinner(true);
      try {
        const { projectId, rowId, tableId, tableType } = this;
        let data = null;
        const jsonForRequest = {
          projectId,
          commentId,
          tableId,
          tableType,
          workspaceId: this.activeWorkspaceId,
        };
        if (this.type === 'project') {
          data = await CommentsApi.deleteProjectComment(jsonForRequest);
          // deleteProjectComment
        } else {
          data = await CommentsApi.deleteRowComment({
            ...jsonForRequest,
            rowId,
            privacy,
          });
        }
        this.deleteComment({
          id: data.data.response.id,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    editRowCommentAction({ id, message, commentType, privacy, cellPointer, mentioned }) {
      this.focusMessage = true;
      this.message = message;
      this.editedRowId = id;
      this.localCellPointer = cellPointer;
      this.allMentionUsers = mentioned;
      this.setPrivateMode({
        commentType, privacy,
      });
    },
    setPrivateMode({ commentType, privacy }) {
      if (privacy === 'public') {
        this.privateMode = null;
      } else if (privacy === 'private' && commentType === 'text') {
        this.privateMode = privacy;
      } else {
        this.privateMode = commentType;
      }
    },
    async getRowComments({ scanIndexForward = false } = {
    }) {
      try {
        const { rowId, type, tableId, tableType, projectId, slant, cellPointer, mode } = this;
        const { query } = this.$route;
        if (!this.showCommentsModal) {
          return;
        }
        if (!type) {
          this.$router.replace({
            query: {
              ...query,
              rowId,
            },
          }).catch(() => {
          });
        }
        let response = null;
        const jsonForRequest = {
          projectId,
          tableId,
          tableType,
          nextToken: scanIndexForward ? this.nextTokenForUnread : this.nextToken,
          scanIndexForward,
          limit: 1000,
          workspaceId: this.activeWorkspaceId,
        };
        if (type === 'project') {
          response = await CommentsApi.getProjectComments(jsonForRequest);
        } else if (cellPointer) {
          response = await CommentsApi.listCellCommentByPointer({
            ...jsonForRequest,
            rowId,
            cellPointer,
          });
        } else {
          response = await CommentsApi.getRowComments({
            ...jsonForRequest,
            versionId: query.version,
            rowId,
            createdDate: slant?.lastReadCommentDate,
            // limit: scanIndexForward && !this.nextTokenForUnread ? 2 : 5,
          });
        }
        const { comments, nextToken } = response.data.response;
        let commentsForSorting = [...this.comments, ...comments];
        commentsForSorting = this.lodash.uniqBy(commentsForSorting, 'id');
        const sortedComments = this.lodash.orderBy(commentsForSorting,
          ['createdDate'], ['asc']);
        this.comments = sortedComments;
        if (mode) {
          this.privateMode = mode;
        }
        this.comments.forEach(comment => this.getAvatarsForComments(comment));
        if (scanIndexForward) {
          this.nextTokenForUnread = nextToken;
        } else {
          this.nextToken = nextToken;
        }
        this.checkMentionedComment();
        if (this.$route.query?.fromGeneralComments) {
          this.$router.replace({
            query: {
              rowId,
              fromGeneralComments: undefined,
            },
          }).catch(() => {
          });
        }
      } catch (err) {
        console.log(err);
        this.dialog = false;
      } finally {
        this.spinner(false);
      }
    },
    checkMentionedComment() {
      const { query } = this.$route;
      const { scrolledComment } = this;
      const { commentId } = query;
      if (commentId && !scrolledComment) {
        this.scrollToComment({
          id: commentId,
          mentionedComment: true,
        });
      }
    },
    async createRowCommentAttachment({ documentId, commentId }) {
      this.valueBlur && await this.$refs.rowCommentsForm.manageRowComment();
      try {
        let data = null;
        const { rowId, tableId, tableType, privateMode, type, projectId, cellPointer } = this;
        const jsonForRequest = {
          projectId,
          documentId,
          tableId,
          tableType,
          commentId,
          workspaceId: this.activeWorkspaceId,
          // privacy: privateMode || cellPointer ? 'private' : 'public',
          privacy: privateMode ? 'private' : 'public',
        };
        //createProjectCommentAttachment
        if (type === 'project') {
          data = await CommentsApi.createProjectCommentAttachment(jsonForRequest);
        } else {
          data = await CommentsApi.createRowCommentAttachment({
            ...jsonForRequest,
            rowId,
            cellPointer,
          });
        }
        this.setComment(data.data.response);
        this.fileForPreview = null;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async createRowComment({ mentioned, message, resolve }) {
      const { tableType, privateMode, tableId, rowId, type, projectId, cellPointer } = this;
      try {
        let data = null;
        const jsonForRequest = {
          projectId,
          message,
          tableId,
          tableType,
          mentioned,
          // privacy: privateMode || cellPointer ? 'private' : 'public',
          privacy: privateMode ? 'private' : 'public',
          commentType: privateMode === 'offer' ? 'offer' : 'text',
          workspaceId: this.activeWorkspaceId,
        };
        if (type === 'project') {
          data = await CommentsApi.createProjectComment(jsonForRequest);
        } else {
          data = await CommentsApi.createRowComment({
            ...jsonForRequest,
            rowId,
            cellPointer,
          });
        }
        this.clearCommentForm();
        this.setComment(data.data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
        resolve(true);
      }
    },
    async editRowComment({
      mentioned: mentionedUsers,
      message: messageForRequest,
      cellCommentResolved: cellCommentResolvedForRequest,
      id: commentId,
      privacy: privacyForRequest,
      commentType: commentTypeForRequest,
      manageCommentResolve = false,
    }) {
      try {
        const { tableType, privateMode, tableId, rowId, type, projectId } = this;
        let calcCommentType = null;
        let calcPrivacy = null;
        if (privacyForRequest) {
          calcCommentType = commentTypeForRequest;
          calcPrivacy = privacyForRequest;
        } else {
          calcCommentType = privateMode === 'offer' ? 'offer' : 'text';
          calcPrivacy = privateMode ? 'private' : 'public';
        }
        const jsonForRequest = {
          projectId,
          tableId,
          tableType,
          commentId,
          attributes: {
            ...(commentTypeForRequest !== 'attachment' && {
              commentType: calcCommentType,
              privacy: calcPrivacy,
              ...(!manageCommentResolve && {
                mentioned: mentionedUsers,
                message: messageForRequest,
              }),
            }),
            cellCommentResolved: cellCommentResolvedForRequest,
          },
          workspaceId: this.activeWorkspaceId,
        };
        let data = null;
        if (type === 'project') {
          data = await CommentsApi.editProjectComment(jsonForRequest);
        } else {
          data = await CommentsApi.editRowComment({
            ...jsonForRequest,
            rowId,
          });
        }
        this.clearCommentForm();
        this.focusMessage = false;
        this.setComment(data.data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    clearCommentForm() {
      if (!this.$refs?.rowCommentsForm) return;
      this.$refs?.rowCommentsForm.clearCommentForm();
    },
  },
};
</script>

<style scoped lang="scss">
.file-uploads {
  display: none;
}
.comment-file-container {
  width: 70%;
  max-width: 320px;
  @media only screen and (max-width: 599px) {
    width: 100%;
  }
  &__content {
    min-height: 66px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    padding: 8px;
    &:hover {
      transition: all 0.15s ease-in;
      border-color: var(--v-lightGrey-base);
    }
    .download-p {
      a {
        z-index: 1;
      }
      span:hover {
        color: var(--v-lightGrey-base);
      }
    }
  }
}
</style>
