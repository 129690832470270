<template>
  <div class="schedule-container app-container-flex__full">
    <ProjectDetailsTitle
      :detail-project-data="detailProjectData"
      :user-info="userInfo"
      :user-id="userId"
      @toggleNav="primaryNavIsOpen = !primaryNavIsOpen">
      <template v-if="!isMobile">
        <SwitcherToListing
          :value="isListingView"
          show-icon
          @toggleViewMode="toggleViewModeHandler" />

        <ProjectDetailsViews data-test="select_view_dropdown_button" />

        <SummaryComments
          v-if="isShowSummaryComments"
          :is-rows-loading="progressLinear.show"
          :data="dataForSummaryComments"
          :general-comments-modal.sync="generalCommentsModal"
          @goToRowComments="goToRowComments = !goToRowComments" />
      </template>
    </ProjectDetailsTitle>

    <div
      :class="{ 'ps-22': !isMobile }"
      class="d-flex flex-column flex-grow-1 flex-shrink-1 relative o-hidden">
      <ProjectDetailsNavigation
        :hide-compare="hideCompareViews"
        :primary-is-open="primaryNavIsOpen"
        :show-export="showExportToBlock"
        :child-props="childProps"
        :show-issuance="detailProjectData !== null && role !== 'viewer' && !hideVersions"
        :user-info="userInfo"
        :user-id="userId"
        @updateVersionRow="getDefaultRowsForView">
        <template #topMenu="{ sizing }">
          <template v-if="isMobile">
            <v-list-item>
              <SwitcherToListing
                :value="isListingView"
                @toggleViewMode="toggleViewModeHandler" />
            </v-list-item>

            <v-list-item>
              <ProjectDetailsViews data-test="select_view_dropdown_button" />
            </v-list-item>

            <v-list-item>
              <SummaryComments
                v-if="isShowSummaryComments"
                :is-rows-loading="progressLinear.show"
                :data="dataForSummaryComments"
                :general-comments-modal.sync="generalCommentsModal"
                @goToRowComments="goToRowComments = !goToRowComments" />
            </v-list-item>
          </template>

          <v-list-item>
            <v-list-item-icon>
              <ProjectDetailsDocuments
                v-if="!isUnsuccessfulPayment && !hideDocuments"
                :images="images"
                :sizing="sizing"
                data-test="project_documents_button"
                @saveProjectDocuments="saveProjectDocuments"
                @deleteFiles="deleteFiles"
                @getDocuments="getDocuments({ projectId, scheduleId })"
                @error="onError"
                @clearAllItems="images = []"
                @getUrlForValues="getUrlForValues" />
            </v-list-item-icon>
          </v-list-item>
        </template>

        <template #bottomMenu>
          <!-- quick share link -->
          <v-list-item v-if="!isVersionMode && !hideSharedLink">
            <ProjectDetailsSharedLink :project-id="projectId" />
          </v-list-item>

          <v-list-item v-if="!hideRemoveProject">
            <ListDeletedRows />
          </v-list-item>
        </template>
      </ProjectDetailsNavigation>

      <div class="py-6 d-flex justify-center align-center">
        <!-- search bar -->
        <ProjectDetailsSearchCells v-if="!isMobile && showSearchCellsBlock && !hideFindInView" />
      </div>

      <div class="select-view-container o-hidden">
        <ProjectDetailsSummaryBudgetView v-if="canViewSummaryProjectBudgetViewPanel" />
        <ProjectDetailsSummarySustainabilityView v-if="canViewSummarySustainabilityViewPanel" />
        <div
          class="table-container"
          :class="!isListingView && 'hide-scroll'"
          :style="{
            'height': isListingView ? '100%' : '',
          }">
          <div />
          <div v-if="isLoadingRowData">
            <ListingSkeleton v-if="selectedView && isListingView" />
            <ScheduleSkeleton
              v-else
              :body-rows="10" />
          </div>
          <ProjectDetailsGalleryBlock
            v-show="selectedView && isListingView"
            :is-shown="selectedView && isListingView"
            :data="currentViewInfo" />
          <ProjectDetailsTableSchedule
            ref="tableScheduleWrapper"
            :data="dataArr"
            :disabled-edit-mode="disabledEditMode"
            :go-to-row-comments="goToRowComments"
            :is-free-workspace="isFreeWorkspace"
            :is-listing-view="isListingView"
            :is-show-table="selectedView && !isListingView"
            :shared-link="sharedLink"
            custom-id="regularTable"
            @getDocuments="getDocuments({ projectId, scheduleId })"
            @onDragEnd="sortHeadersAfterDrag" />
          <AppProgressLinear />
        </div>
        <ProjectDetailsRowExpandedView
          :disabled-edit-mode="disabledEditMode"
          :row-id="modalRowId"
          :item="currentRowItem"
          :mode="modalMode"
          @updateCell="updateCell" />
        <CollectionsCreateProductDialog
          :is-allow-comparison="false"
          :is-dialog-collection="false" />
      </div>
    </div>

    <AppAddButtonNoMenu
      v-if="!hideAddingNewRow"
      :access="addNewRowAccess"
      :click-callback="addNewRow" />
  </div>
</template>
<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';

import productHeaders from '@/constants/productHeaders';
import { TYPE_DOCUMENTS } from '@/constants/projectDocument';
import {
  SORTABLE_ACTIONS_WIDTH, DEFAULT_PROJECT_VERSION, SORTABLE_FIELDS_PROJECTS,
} from '@/constants/scheduleViews';

import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import ListingSkeleton from '@/components/App/AppSkeleton/ListingSkeleton';
import ProjectDetailsDocuments from '@/components/ProjectDetails/ProjectDetailsDocuments';
import ProjectDetailsGalleryBlock from '@/components/ProjectDetails/ProjectDetailsGalleryBlock';
import ProjectDetailsRowExpandedView from '@/components/ProjectDetails/ProjectDetailsRowExpandedView';
import ProjectDetailsSearchCells from '@/components/ProjectDetails/ProjectDetailsSearchCells';
import ProjectDetailsSummaryBudgetView from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummaryBudgetView';
import ProjectDetailsSummarySustainabilityView from '@/components/ProjectDetails/ProjectDetailsSummary/ProjectDetailsSummarySustainabilityView';
import ProjectDetailsTableSchedule from '@/components/ProjectDetails/ProjectDetailsTableSchedule';
import ProjectDetailsTitle from '@/components/ProjectDetails/ProjectDetailsTitle';
import ProjectDetailsViews from '@/components/ProjectDetails/ProjectDetailsViews';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
import SummaryComments from '@/components/ProjectDetails/ProjectDetailsSummaryComments';

import DisableEditMode from '@/mixins/DisableEditMode';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import ParseItemsAttachment from '@/mixins/ParseItemsAttachment';
import ScheduleDocuments from '@/mixins/ScheduleDocuments';
import SetGalleryViewToAllScheduleViews from '@/mixins/SetGalleryViewToAllScheduleViews';
import ToggleViewMode from '@/mixins/ToggleViewMode';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import UndoActions from '@/mixins/UndoActions';

import ProjectDocumentsApi from '@/services/graphql/projectDocuments';

import {
  applyChangesToAnotherArray, sortHelper,
} from '@/utils';

export default {
  name: 'ProjectDetails',
  components: {
    CollectionsCreateProductDialog,
    ListingSkeleton,
    ProjectDetailsDocuments,
    ProjectDetailsGalleryBlock,
    ProjectDetailsRowExpandedView,
    ProjectDetailsSearchCells,
    ProjectDetailsSummaryBudgetView,
    ProjectDetailsSummarySustainabilityView,
    ProjectDetailsTableSchedule,
    ProjectDetailsTitle,
    ProjectDetailsViews,
    ScheduleSkeleton,
    SummaryComments,
    AppProgressLinear: () => import('@/components/App/AppProgressLinear'),
    AppAddButtonNoMenu: () => import('@/components/App/AppAddButtonNoMenu'),
    ProjectDetailsNavigation: () => import('@/components/ProjectDetails/ProjectDetailsNavigation'),
    ListDeletedRows: () => import('@/components/ProjectDetails/ProjectDetailsTableSchedule/TableListDeletedRows'),
    ProjectDetailsSharedLink: () => import('@/components/ProjectDetails/ProjectDetailsSharedLink'),
    SwitcherToListing: () => import('@/components/App/AppListingElements/AppSwitcherToListing'),
  },
  mixins: [
    DisableEditMode,
    LoadingSpinnerReset,
    ParseItemsAttachment,
    ScheduleDocuments,
    SetGalleryViewToAllScheduleViews,
    ToggleViewMode,
    ToggleViewCondition,
    UndoActions,
  ],
  props: {
    hideSummaryComments: {
      type: Boolean,
      default: false,
    },
    isSharedLink: {
      type: Boolean,
      default: false,
    },
    hideVersions: {
      type: Boolean,
      default: false,
    },
    hideCompareViews: {
      type: Boolean,
      default: false,
    },
    hideSharedLink: {
      type: Boolean,
      default: false,
    },
    hideRemoveProject: {
      type: Boolean,
      default: false,
    },
    hideAddingNewRow: {
      type: Boolean,
      default: false,
    },
    hideDocuments: {
      type: Boolean,
      default: false,
    },
    hideFindInView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    firstUpload: true,
    generalCommentsModal: false,
    goToRowComments: false,
    images: [],
    intervalId: null,
    inc: 96,
    isVisibleTable: false,
    isMasonryPage: true,
    printFormat: 'tabloid',
    primaryNavIsOpen: false,
    tableType: 'schedule',
  }),
  computed: {
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState([
      'role',
      'subscriptions',
      'showSpinner',
      'userInfo',
      'isLoadingRowData',
      'progressLinear',
    ]),
    ...mapState('ProjectDetailsTableSchedule',
      [
        'scheduleId',
        'creatorLogo',
        'defaultFields',
        'modalRowId',
        'modalMode',
      ]),
    ...mapState('ScheduleViews', ['activeTags', 'selectedViewId']),
    ...mapGetters([
      'premiumGroup',
      'collaboratorGroup',
      'userId',
      'isShowProgressLinear',
    ]),
    ...mapGetters({
      customViews: 'ScheduleViews/customViews',
    }),
    ...mapGetters('ScheduleRows', ['currentRowItem', 'viewInfoToRendering', 'sortedViewInfo']),
    ...mapGetters('Workspace', ['isFreeWorkspace']),
    ...mapGetters('ScheduleViews', ['isSelectedProjectBudgetView', 'isSelectedSustainabilityView', 'sortingData', 'selectedView', 'getSelectedViewById', 'tableHeaders']),
    ...mapGetters('UserProfile', ['isUnsuccessfulPayment']),
    ...mapGetters({
      userId: 'userId',
    }),
    ...mapGetters('FeatureFlags', [
      'isUsedQuickIndex',
      'isVisiblePrintButton',
    ]),
    ...mapGetters('UserRoles', ['canViewSummaryProjectBudgetView', 'canViewSummarySustainabilityView', 'canUseExportToForCollaboratorRole', 'canModifyTableRows']),
    ...mapGetters('ProjectVersions', ['isVersionMode', 'getCurrentVersion']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    /**
     * @typedef {object} DataProperties
     * @property {boolean} canUseExportToForCollaboratorRole - Indicates whether a user with the "collaborator" role can use the Export To feature.
     * @property {object|null} detailProjectData - The project data to be displayed.
     * @property {string} role - The user's role in the project.
     */
    childProps() {
      const { isSharedLink, isVisiblePrint } = this;
      return {
        isSharedLink,
        isVisiblePrint,
        'table-type': 'schedule',
      };
    },
    /**
     * Determines whether the Export To Block should be displayed.
     *
     * @returns {boolean} True if Export To Block should be displayed, false otherwise.
     */
    showExportToBlock() {
      /**
       * @type {DataProperties}
       */
      const { canUseExportToForCollaboratorRole: canUseExportTo, detailProjectData, role } = this;

      return canUseExportTo(role) && detailProjectData !== null;
    },

    /**
     * Check if the summary comments should be shown.
     *
     * @return {boolean} Returns true if the summary comments should be shown, otherwise false.
     */
    isShowSummaryComments() {
      /**
       * Properties related to the current data.
       *
       * @typedef {object} DataProperties
       * @property {boolean} hideSummaryComments - A boolean flag indicating whether summary comments should be hidden.
       * @property {string} scheduleId - A unique identifier for the schedule.
       */

      /** @type {DataProperties} */
      const { hideSummaryComments, scheduleId } = this;

      return !hideSummaryComments && !!scheduleId;
    },
    addNewRowAccess() {
      return this.canModifyTableRows(this.role);
    },
    isVisiblePrint() {
      return this.detailProjectData && !this.isListingView && this.isVisiblePrintButton;
    },
    getWidthOfTable() {
      let tableWidth = 0;
      for (let i = 0; i < this.tableHeaders.length; i++) {
        const column = this.tableHeaders[i];
        const {
          columnSize = {
            size: 60,
            isCustomSortable: false,
          },
        } = column ?? {
        };
        const {
          size,
          isCustomSortable,
        } = columnSize;
        if (isCustomSortable) {
          tableWidth += size + SORTABLE_ACTIONS_WIDTH;
        } else {
          tableWidth += size;
        }
      }
      let listWidth;
      let result;
      if (this.printFormat === 'tabloid') {
        // Tabloid = 11×17
        listWidth = 17 * this.inc;
        result = 100 * (tableWidth / listWidth) * 1.15;
      }
      if (this.printFormat === 'a0') {
        // A0 = 33.1×46.8
        listWidth = 33.1 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a1') {
        // A1 = 23.4×33.1
        listWidth = 23.4 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a2') {
        // A2 = 16.5×23.4
        listWidth = 16.5 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      return Math.ceil(result);
    },
    htmlToPdfOptions() {
      return {
        margin: 10,
        filename: `${this.getCurrentView} - ${this.getDetailProjectTitle}.pdf`,
        enableLinks: true,
        image: {
          type: 'jpeg', quality: 2,
        },
        html2canvas: {

          scale: 2,
          removeContainer: true,
          useCORS: true,
        },
        jsPDF: {

          format: this.printFormat,
          orientation: 'landscape',
          quality: 2,
        },
      };
    },
    getScale() {
      const width = this.getWidthOfTable;
      const result = 100 / width;
      if (result > 1) {
        return 1;
      }
      return result;
    },
    showSearchCellsBlock() {
      const { isShowProgressLinear, dataArr } = this;
      return !isShowProgressLinear && dataArr.length;
    },
    defaultSortIem() {
      if (this.selectedView && this.selectedView?.sortingField) {
        return {
          name: this.selectedView.sortingField,
          request: this.selectedView.sortingField,
        };
      }
      return null;
    },
    sortItems() {
      return SORTABLE_FIELDS_PROJECTS.map(e => ({
        name: e,
        request: e,
      }));
    },
    getCurrentView() {
      const { name = '' } = this.selectedView ?? {
      };
      return name;
    },
    dataArr() {
      return this.viewInfoToRendering;
    },
    isListingView() {
      return this.toggleViewCondition(this.selectedViewId);
    },
    renderedCustomDefaultFields() {
      const customDefaultFields = this.selectedView?.customDefaultFields;
      return customDefaultFields?.length ? customDefaultFields : this.defaultFields;
    },
    currentViewInfo: {
      get() {
        return this.sortedViewInfo;
      },
      set(val) {
        this.setViewInfo(val);
      },
    },
    dataForSummaryComments() {
      return this.currentViewInfo.map(item => ({
        id: item.id,
        type: item[productHeaders.TYPE_DESIGNATION],
        commentsAmount: item.commentsAmount || 0,
      }));
    },
    projectId() {
      const {
        params = {
        },
      } = this.$route ?? {
      };
      const { id = '' } = params;
      return id;
    },
    canViewSummaryProjectBudgetViewPanel() {
      return this.canViewSummaryProjectBudgetView(this.role).allowed
        && this.isSelectedProjectBudgetView;
    },
    canViewSummarySustainabilityViewPanel() {
      return this.canViewSummarySustainabilityView(this.role).allowed
        && this.isSelectedSustainabilityView;
    },
    sharedLink() {
      return this.isSharedLink;
    },
  },
  watch: {
    getCurrentVersion(val) {
      val !== DEFAULT_PROJECT_VERSION ? this.unsubscribeRowRelated() : this.subscribeRowRelated();
    },
    selectedViewId(val, oldVal) {
      try {
        const oldView = this.getSelectedViewById(oldVal);
        this.resetState();
        if (!this.firstUpload && oldView?.sortingField !== this.selectedView?.sortingField) {
          this.setViewsSorting({
            sortingData: sortHelper(this.sortingData.viewId, this.selectedView.sortingField),
          });
        }
        if (this.firstUpload) {
          if ((!oldView || oldView?.filterTag
            || oldView?.sortingField !== this.selectedView?.sortingField) && this.selectedView || this.selectedView?.filterTag) {
            this.getDefaultRowsForView();
          }
          this.firstUpload = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    const { projectId, activeWorkspaceId, sharedLink, setUuidToUpdateCellRequest: setUuid } = this;
    setUuid(); // Call the setUuidToUpdateCellRequest method to generate and set the UUID
    this.changeRole('viewer');
    await this.getProjectPermissions();
    this.needUserPremium({
      workspaceId: activeWorkspaceId,
      resourceType: 'project',
      resourceId: projectId,
    });
    await this.getListProjectVersions({
      projectId,
    });
    this.getProjectAggregation({
      projectId,
    });
    await this.getProjectByIdHandle(projectId);
    await this.getSchedule({
      sharedLink,
      projectId,
    });
    const { scheduleId } = this;
    this.getDocuments({
      projectId, scheduleId,
    });
    this.setGalleryViewOnMobile();
    this.userId && this.subscribeRowRelated();
  },
  beforeDestroy() {
    this.clearCommentsModalTrigger();
  },
  destroyed() {
    this.setNeedUserPremium(false);
    this.unsubscribeRowRelated();
    this.setDetailDataOfProject(null);
    this.setAggregationData(null);
    this.$store.commit('ProjectDetailsTableSchedule/setCreatorLogo', null);
    this.$store.commit('ProjectDetailsTableSchedule/setCreatorLogoKey', null);
    this.$store.commit('ProjectDetailsTableSchedule/setSchema', []);
    this.$store.commit('ProjectDetailsTableSchedule/setScheduleId', null);
    this.$store.commit('Comments/clearSlants');
    this.currentViewInfo = [];
    this.setSelectedViewId(null);
    this.closeProgressLinear();
    this.setScheduleSessionUuid(null);
    this.setCellsVersions({
    });
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      getListProjectVersions: 'ProjectVersions/getListProjectVersions',
      getProjectPermissions: 'ProjectDetailsTableSchedule/getProjectPermissions',
      getSchedule: 'ProjectDetailsTableSchedule/getSchedule',
      subscribeCommentsAmount: 'Comments/subscribeCommentsAmount',
      setSelectedViewId: 'ScheduleViews/setSelectedViewId',
      needUserPremium: 'Workspace/needUserPremium',
      setViewInfo: 'ScheduleRows/setViewInfo',
      createEmptyRow: 'ScheduleRows/createEmptyRow',
      subscribeDeleteRow: 'ScheduleRows/subscribeDeleteRow',
      getRows: 'ScheduleRows/getRows',
      subscribeUnlockProject: 'ProjectDetailsTableSchedule/subscribeUnlockProject',
      subscribeRowRelated: 'ProjectDetailsTableSchedule/subscribeRowRelated',
      unsubscribeRowRelated: 'ProjectDetailsTableSchedule/unsubscribeRowRelated',
      updateViewColumnOrder: 'ScheduleViews/updateViewColumnOrder',
      updateViewSettings: 'ScheduleViews/updateViewSettings',
      updateViewOrder: 'ScheduleViews/updateViewOrder',
      getProjectAggregation: 'ProjectAggregation/getProjectAggregation',
      getProjectById: 'ProjectDetails/getProjectById',
      setViewsSorting: 'ScheduleViews/setViewsSorting',
      clearCommentsModalTrigger: 'ProjectDetailsTableSchedule/clearCommentsModalTrigger',
    }),
    ...mapMutations({
      changeRole: 'changeRole',
      clearSearchString: 'SearchProjectCells/clearSearchString',
      closeProgressLinear: 'closeProgressLinear',
      openSnackBar: 'openSnackBar',
      resetState: 'ScheduleCompare/resetState',
      setAggregationData: 'ProjectAggregation/setAggregationData',
      setAssociatedDocuments: 'ProjectDetails/setAssociatedDocuments',
      setCellsVersions: 'ScheduleRows/setCellsVersions',
      setCustomViewRows: 'ProjectDetailsTableSchedule/setCustomViewRows',
      setDetailDataOfProject: 'ProjectDetails/setDetailDataOfProject',
      setModalRowId: 'ProjectDetailsTableSchedule/setModalRowId',
      setNeedUserPremium: 'Workspace/setNeedUserPremium',
      setScheduleSessionUuid: 'ProjectDetailsTableSchedule/setScheduleSessionUuid',
      showShareModalAction: 'showShareModalAction',
      spinner: 'spinner',
    }),
    addNewRow() {
      if (!this.addNewRowAccess.allowed) return;

      this.createEmptyRow({
        tableId: this.scheduleId,
        tableType: 'schedule',
        projectId: this.$route.params.id,
      });
    },
    sortProject(obj) {
      this.updateViewSettings({
        viewId: this.selectedView.id,
        sortingField: obj.name,
      });
    },
    toggleViewModeHandler(e) {
      this.clearSearchString();
      this.toggleViewMode({
        criteria: this.selectedViewId,
        value: e,
      });
    },
    /**
     * Generates a new UUID and sets it as the schedule session UUID for an update cell request.
     * @returns {void}
     */
    setUuidToUpdateCellRequest() {
      const version = uuid();
      this.setScheduleSessionUuid(version);
    },
    hasDownloaded() {
      this.isVisibleTable = false;
    },
    updateCell(data) {
      const { tableScheduleWrapper: wrapper } = this.$refs ?? {
      };
      if (!wrapper) return;
      /**
       * call updateCell method from ProjectDetailsTableSchedule
       * TODO: need to refactor
       */
      wrapper.updateCell(data);
    },
    async getDocuments({ projectId = '', scheduleId = '' }) {
      if (!scheduleId || !projectId) return false;
      const requests = TYPE_DOCUMENTS.map(async ({ type: documentType }) => await this.getDocumentType({
        documentType,
        projectId,
        scheduleId,
      }));
      const response = await Promise.all(requests);
      let documents = {
      };
      for (const doc of response) {
        documents = {
          ...documents, ...doc,
        };
      }
      this.setAssociatedDocuments(documents);
    },
    async getDocumentType(payload) {
      const {
        documentType,
        projectId,
        nextToken = null,
        oldItems = [],
        scheduleId,
      } = payload;
      let items = [];
      try {
        this.spinner(true);
        const { activeWorkspaceId: workspaceId } = this;
        const { data } = await ProjectDocumentsApi.getProjectDocumentsV2({
          workspaceId,
          documentType,
          scheduleId,
          projectId,
          nextToken,
          limit: 100,
        });
        const { data: newItems, nextToken: responseNextToken } = data.response;
        if (newItems) {
          items = [...oldItems, ...newItems];
        }
        if (responseNextToken) return await this.getDocumentType({
          ...payload,
          oldItems: items,
          nextToken: responseNextToken,
        });
        return {
          [documentType]: await this.getImageFromStorage(items),
        };
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async sortHeadersAfterDrag({
      customColumnOrder,
      oldCustomColumnOrder,
      changes,
    }) {
      const clonedDefaultFields = cloneDeep(this.renderedCustomDefaultFields);
      const oldCustomDefaultFields = clonedDefaultFields;
      const customDefaultFields = applyChangesToAnotherArray(customColumnOrder, clonedDefaultFields, changes);
      const { activeWorkspaceId: workspaceId, scheduleId, projectId, selectedView } = this;
      try {
        this.updateViewOrder({
          ...selectedView,
          customColumnOrder,
          customDefaultFields,
        });
        await this.updateViewColumnOrder({
          variables: {
            workspaceId,
            resourceType: 'schedule',
            resourceId: scheduleId,
            projectId,
            viewId: selectedView.id,
            customColumnOrder,
            customDefaultFields,
          },
          updateViewData: false,
        });
      } catch (err) {
        this.updateViewOrder({
          ...selectedView,
          customColumnOrder: oldCustomColumnOrder,
          customDefaultFields: oldCustomDefaultFields,
        });
      }
    },
    handleRequestAnInvite() {
      const { action, email, type, generalComments } = this.$route.query;
      if (action === 'invite' && email && type) {
        this.showShareModalAction();
      } else if (generalComments) {
        this.generalCommentsModal = true;
      }
    },
    async getDefaultRowsForView({ id, isNewVersion } = {
    }) {
      const { query } = this.$route;
      let versionId = id === undefined ? query?.version : id;
      if (versionId === DEFAULT_PROJECT_VERSION) {
        versionId = undefined;
      }
      if (versionId) {
        this.subscribeUnlockProject({
          requests: {
            getRowsForVersion: {
              versionId,
              sharedLink: this.sharedLink,
            },
          },
        });
      }
      await this.getRows({
        variables: {
          versionId,
          sharedLink: this.sharedLink,
        },
      });
      if (versionId && !isNewVersion) {
        this.openSnackBar({
          text: 'Project is in Read-Only Mode',
        });
      }
      this.handleRequestAnInvite();
    },
    async getProjectByIdHandle(id) {
      const { sharedLink } = this;
      await this.getProjectById({
        id,
        sharedLink,
      });
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>
