<template>
  <div>
    <!-- project settings -->
    <ProjectDetailControlsView
      :users-initial="usersInitial"
      :set-selected-view="setSelectedView"
      :users-to-delete="usersToDelete"
      :roles-to-delete="rolesToDelete"
      :item="selectedItem"
      :query-information="queryInformation"
      :dialog-status.sync="dialogStatus"
      @deleteView="deleteView"
      @update-headers="updateHeaders"
      @closeAutocomplete="closeAutocomplete"
      @initSelectedView="initSelectedView"
      @initRolesToDelete="initRolesToDelete"
      @usersToRemove="usersToRemove" />
    <!-- new view copy -->
    <ProjectDetailViewCopy
      :views="views"
      :selected-view="selectedCopiedView"
      :dialog-status.sync="copyDialogStatus"
      @onCopy="onCopy"
      @updateCopiedView="updateCopiedView"
      @closeAutocomplete="closeAutocomplete" />
    <!-- dropdown -->
    <v-select
      ref="views"
      v-model="selected"
      :items="views"
      :menu-props="{
        nudgeBottom: 42,
      }"
      color="black"
      class="sourcery__select"
      item-text="name"
      dense
      full-width
      hide-details
      outlined
      rounded
      return-object>
      <template #append>
        <span class="sourcery__icon-wrapper black size-22">
          <img src="@/assets/icons/chevron-down-icon.svg">
        </span>
      </template>

      <template #selection="{ item }">
        <span
          class="fs-14 text-uppercase">
          {{ item && item.name }}
        </span>
      </template>

      <!-- create new project btn -->
      <template #prepend-item>
        <v-list-item
          v-if="createNewProjectView"
          class="v-list-item--link blue white--text"
          @click.stop="showCopyPopup">
          {{ CREATE_NEW_VIEW }}
        </v-list-item>
      </template>

      <!-- item view -->
      <template #item="{ item, on }">
        <v-list-item
          :class="{'v-item--active v-list-item--active': item.name === selected.name }"
          v-on="on">
          <v-tooltip
            :disabled="showViewName(item.name).showTool"
            top>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title
                v-bind="attrs"
                :data-test="setDataTest(item)"
                v-on="on">
                {{ showViewName(item.name).item }}
              </v-list-item-title>
              <div class="action-wrapper">
                <app-icon-btn
                  v-if="isDeletableView(item)"
                  :access="canWorkWithModifyingBasedOnTag"
                  icon-color="black"
                  icon-height="20px"
                  icon-name="mdi-delete"
                  bottom
                  :show-tooltip="!canWorkWithModifyingBasedOnTag || !canWorkWithModifyingBasedOnTag.allowed"
                  @click.stop="canWorkWithModifyingBasedOnTag.allowed && deleteView(item)" />
                <app-icon-btn
                  :access="canViewInfo"
                  class="d-flex align-self-center"
                  icon-color="black"
                  icon-height="20px"
                  icon-name="mdi-settings"
                  bottom
                  :show-tooltip="!canViewInfo || !canViewInfo.allowed"
                  @click.stop="canViewInfo.allowed && showItems(item)" />
              </div>
            </template>
            <span>
              {{ item.name || item }}
            </span>
          </v-tooltip>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import ProjectViewsApi from '@/services/graphql/projectViews';
import ProjectDetailControlsView from './ProjectDetailControlsView';
import ProjectDetailViewCopy from './ProjectDetailViewCopy';
import {
  sortHelper,
  convertStringToArray,
} from '@/utils';
import { isViewDeletable } from '@/utils/scheduleViews';
import {
  DATA_TEST_OF_VIEWS,
  CREATE_NEW_VIEW,
  SORT_TOP,
} from '@/constants/scheduleViews';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';
export default {
  name: 'ProjectDetailsViews',
  components: {
    ProjectDetailControlsView,
    ProjectDetailViewCopy,
  },
  data() {
    return {
      selectedItem: {
      },
      selectedCopiedView: {
      },
      usersInitial: [],
      usersToDelete: [],
      rolesToDelete: [],
      rolesToCompareForDelete: [],
      setSelectedView: [],
      dialogStatus: false,
      copyDialogStatus: false,
      queryInformation: {
      },
      CREATE_NEW_VIEW,
      syncTime: null,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['createNewProjectView']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    ...mapGetters('UserRoles', ['canModifyCustomViewBasedOnTag', 'canModifyViews']),
    ...mapState(['role']),
    ...mapState('ScheduleViews', ['views']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    projectId() {
      return this.$route.params.id;
    },
    canViewInfo() {
      return this.canModifyViews(this.role);
    },
    canWorkWithModifyingBasedOnTag() {
      return this.canModifyCustomViewBasedOnTag(this.role);
    },
    selected: {
      get() {
        return this.selectedView;
      },
      set(val) {
        clearTimeout(this.syncTime);
        if (this.selectedView?.id !== val?.id) {
          this.setUploadingSpinner(true);
          this.syncTime = setTimeout(() => {
            if (this.selectedView?.id !== val?.id) {
              this.setViewId(val);
            }
          }, 10);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setViewId: 'ScheduleViews/setSelectedViewId',
      setViews: 'ScheduleViews/setViews',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setUploadingSpinner: 'setUploadingSpinner',
    }),
    updateHeaders() {
      const { id = '' } = this.selectedItem || {
      };
      const updatedView = this.views.find(e => e?.id === id);
      if (updatedView) this.selectedItem = updatedView;
      this.$emit('update-headers');
    },
    initSelectedView(data) {
      this.selectedView = data;
    },
    initRolesToDelete(roles) {
      this.rolesToDelete = this.rolesToCompareForDelete.reduce((arr, currentValue) => {
        const { role: currentRole = '' } = currentValue || {
        };
        const isDeletedRol = !roles.includes(currentRole);
        return isDeletedRol ? [...arr, currentRole] : arr;
      }, []);
      console.log(this.rolesToDelete);
    },
    usersToRemove(item) {
      this.usersToDelete.push(item);
    },
    async showItems(item) {
      this.spinner(true);
      const { projectId } = this;
      this.selectedItem = item;
      this.queryInformation = {
        projectId,
        tableId: this.scheduleId,
        tableType: 'schedule',
        viewId: item.id,
        workspaceId: this.activeWorkspaceId,
      };
      this.usersInitial = [];
      this.usersToDelete = [];
      this.rolesToDelete = [];
      try {
        const info = await ProjectViewsApi.getUsersWithSpecialAccessToView(this.queryInformation);
        const { data } = await ProjectViewsApi.getRolesPermissionsToView(this.queryInformation);
        this.usersInitial = info.data.response.map(el => el.user);
        this.setSelectedView = data.response;
        this.rolesToCompareForDelete = data.response;
        this.dialogStatus = true;
      } catch (err) {
        console.log('getUsersWithSpecialAccessToView err', err);
      } finally {
        this.spinner(false);
      }
    },
    showCopyPopup() {
      this.spinner(true);
      try {
        this.copyDialogStatus = true;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    updateCopiedView(val) {
      const updatedView = this.views.find(e => e?.id === val.id);
      if (updatedView) this.selectedCopiedView = updatedView;
    },
    async onCopy() {
      this.spinner(true);
      const {
        scheduleId,
        selectedCopiedView,
        activeWorkspaceId,
      } = this;
      try {
        const { data } = await ProjectViewsApi.saveCustomView({
          scheduleId: scheduleId,
          projectId: this.$route.params.id,
          viewId: selectedCopiedView.id,
          viewName: 'New ' + selectedCopiedView.name,
          filterTag: '',
          workspaceId: activeWorkspaceId,
        });
        const { response } = data;
        const item = {
          ...response,
          filterTag: !response.filterTag ? [] : convertStringToArray(response.filterTag),
          sortingData: sortHelper(response.id, response.sortingField, SORT_TOP),
        };
        this.setViews({
          arrViews: [...this.views, item],
        });
        this.setViewId(item);
        this.showItems(item);
        this.$refs.views.isMenuActive = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    setDataTest({ name = null } = {
    }) {
      if (!name) null;
      return DATA_TEST_OF_VIEWS[name];
    },
    setAppropriateView() {
      const { views } = this;
      const currentView = views[views.length - 1];
      this.setViewId(currentView);
    },
    closeAutocomplete() {
      this.$refs.views.isMenuActive = false;
      this.$refs.views.blur();
    },
    async deleteView({ id }) {
      const { selectedView, views } = this;
      try {
        this.spinner(true);
        const { data } = await ProjectViewsApi.deleteView({
          scheduleId: this.scheduleId,
          viewId: id,
          projectId: this.$route.params.id,
          workspaceId: this.activeWorkspaceId,
        });
        const responseView = data.response;
        const arrViews = views.filter(element => element.id !== responseView.id);
        this.setViews({
          arrViews,
        });
        if (selectedView.id === responseView.id) {
          this.setAppropriateView();
        }
        this.$refs.views.isMenuActive = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    showViewName(val) {
      if (val && val.length > 30) {
        const item = `${val.substr(0, 30)}...`;
        return {
          item,
          showTool: false,
        };
      } else {
        return {
          item: val,
          showTool: true,
        };
      }
    },
    isDeletableView(item) {
      return isViewDeletable(item);
    },
  },
};
</script>

<style scoped lang="scss">
.action-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 20px;
}
.create-new-view {
  position: sticky;
  top: 0;
  background-color: var(--v-blue-base);
  z-index: 1;
}
</style>
