var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ProjectDetailControlsView',{attrs:{"users-initial":_vm.usersInitial,"set-selected-view":_vm.setSelectedView,"users-to-delete":_vm.usersToDelete,"roles-to-delete":_vm.rolesToDelete,"item":_vm.selectedItem,"query-information":_vm.queryInformation,"dialog-status":_vm.dialogStatus},on:{"update:dialogStatus":function($event){_vm.dialogStatus=$event},"update:dialog-status":function($event){_vm.dialogStatus=$event},"deleteView":_vm.deleteView,"update-headers":_vm.updateHeaders,"closeAutocomplete":_vm.closeAutocomplete,"initSelectedView":_vm.initSelectedView,"initRolesToDelete":_vm.initRolesToDelete,"usersToRemove":_vm.usersToRemove}}),_c('ProjectDetailViewCopy',{attrs:{"views":_vm.views,"selected-view":_vm.selectedCopiedView,"dialog-status":_vm.copyDialogStatus},on:{"update:dialogStatus":function($event){_vm.copyDialogStatus=$event},"update:dialog-status":function($event){_vm.copyDialogStatus=$event},"onCopy":_vm.onCopy,"updateCopiedView":_vm.updateCopiedView,"closeAutocomplete":_vm.closeAutocomplete}}),_c('v-select',{ref:"views",staticClass:"sourcery__select",attrs:{"items":_vm.views,"menu-props":{
      nudgeBottom: 42,
    },"color":"black","item-text":"name","dense":"","full-width":"","hide-details":"","outlined":"","rounded":"","return-object":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"sourcery__icon-wrapper black size-22"},[_c('img',{attrs:{"src":require("@/assets/icons/chevron-down-icon.svg")}})])]},proxy:true},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"fs-14 text-uppercase"},[_vm._v(" "+_vm._s(item && item.name)+" ")])]}},{key:"prepend-item",fn:function(){return [(_vm.createNewProjectView)?_c('v-list-item',{staticClass:"v-list-item--link blue white--text",on:{"click":function($event){$event.stopPropagation();return _vm.showCopyPopup.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.CREATE_NEW_VIEW)+" ")]):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
    var on = ref.on;
return [_c('v-list-item',_vm._g({class:{'v-item--active v-list-item--active': item.name === _vm.selected.name }},on),[_c('v-tooltip',{attrs:{"disabled":_vm.showViewName(item.name).showTool,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({attrs:{"data-test":_vm.setDataTest(item)}},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showViewName(item.name).item)+" ")]),_c('div',{staticClass:"action-wrapper"},[(_vm.isDeletableView(item))?_c('app-icon-btn',{attrs:{"access":_vm.canWorkWithModifyingBasedOnTag,"icon-color":"black","icon-height":"20px","icon-name":"mdi-delete","bottom":"","show-tooltip":!_vm.canWorkWithModifyingBasedOnTag || !_vm.canWorkWithModifyingBasedOnTag.allowed},on:{"click":function($event){$event.stopPropagation();_vm.canWorkWithModifyingBasedOnTag.allowed && _vm.deleteView(item)}}}):_vm._e(),_c('app-icon-btn',{staticClass:"d-flex align-self-center",attrs:{"access":_vm.canViewInfo,"icon-color":"black","icon-height":"20px","icon-name":"mdi-settings","bottom":"","show-tooltip":!_vm.canViewInfo || !_vm.canViewInfo.allowed},on:{"click":function($event){$event.stopPropagation();_vm.canViewInfo.allowed && _vm.showItems(item)}}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name || item)+" ")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }